type CarrotEnvironment = 'local' | 'staging' | 'production';
const environments: { [key: string]: CarrotEnvironment } = {
  'carrot.pizza': 'local',
  'testcarrot.xyz': 'staging',
  'carrot.com': 'production',
};

export function carrotEnvironment(domain: string) {
  return environments[domain];
}
