import { useJwt } from 'hooks/useJwt';
import { accountManagerBaseUrl } from 'utils/accountManagerBaseUrl';
import { carrotDomain } from 'utils/carrotDomain';

const accountManagerGraphQlUrl = `${accountManagerBaseUrl(carrotDomain())}/graphql`;

function useAuthorizationHeader() {
  const { data } = useJwt();
  const requestHeaders = data?.token
    ? { authorization: `Bearer ${data.token}` }
    : undefined;

  return requestHeaders;
}

export { accountManagerGraphQlUrl, useAuthorizationHeader };
