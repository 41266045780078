import { ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuthorizationHeader } from 'graphql/helpers';
import { client, httpLink } from './client';

export function GraphQlProvider({ children }: { children: React.ReactNode }) {
  const authorizationHeader = useAuthorizationHeader();
  const authenticationLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        ...authorizationHeader,
      },
    };
  });
  client.setLink(authenticationLink.concat(httpLink));

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
