import { Root, RootProps } from 'Root/Root';
import {
  AcceptInvite,
  Analytics,
  BacklinkAnalysis,
  CarrotCrmOptIn,
  ContentOverview,
  ContentProMigration,
  CreateAccount,
  CreateAccountComplete,
  DomainOverview,
  KeywordExplorerOrUpsell,
  MarketIntelligence,
  MembershipEditor,
  MembershipManagement,
  OnboardingRoutes,
  SearchPerformance,
  SignUp,
  SiteAuditRoutes,
  SuperAuthorizedPage,
  SuperBundlesCreate,
  SuperBundlesList,
  SwitchAccount,
  TeamMembersRoutes,
} from 'lazy-routes';
import { AuthorizedPage } from 'pages/AuthorizedPage/AuthorizedPage';
import { BaseTrackingScripts } from 'pages/CreateAccount/BaseTrackingScripts';
import { ErrorPage } from 'pages/ErrorPage/ErrorPage';
import { PageNotFound } from 'pages/PageNotFound/PageNotFound';
import { Navigate, Outlet, type RouteObject } from 'react-router-dom';
import { PageWithAuthenticatedNavs } from 'turnip/PageWithAuthenticatedNavs/PageWithAuthenticatedNavs';
import { PageWithFooter } from 'turnip/PageWithFooter/PageWithFooter';

/**
 * This function returns the routes for the app
 *
 * By using a function to add our high-level providers and components to the
 * component tree, we are able to inject test values when routes are rendered
 * during tests.
 */
export const createRoutes = ({
  queryClient,
  mixpanel,
  googleAnalyticsId,
  intercomId,
}: RootProps): RouteObject[] => [
  {
    element: (
      <Root
        queryClient={queryClient}
        mixpanel={mixpanel}
        googleAnalyticsId={googleAnalyticsId}
        intercomId={intercomId}
      >
        <Outlet />
      </Root>
    ),
    errorElement: (
      <Root
        queryClient={queryClient}
        mixpanel={mixpanel}
        googleAnalyticsId={googleAnalyticsId}
        intercomId={intercomId}
      >
        <PageWithFooter>
          <ErrorPage />
        </PageWithFooter>
      </Root>
    ),
    children: appRoutes,
  },
];

const errorRoute: RouteObject = {
  path: 'error-route',
  Component: () => {
    throw new Error('test');
  },
};

const appRoutes: RouteObject[] = [
  /**
   * Sign-up routes
   */
  {
    element: (
      <BaseTrackingScripts>
        <Outlet />
      </BaseTrackingScripts>
    ),
    children: [
      {
        path: 'create-account',
        element: (
          <PageWithFooter>
            <Outlet />
          </PageWithFooter>
        ),
        children: [
          { index: true, element: <CreateAccount /> },
          { path: 'complete', element: <CreateAccountComplete /> },
        ],
      },
      { path: 'sign-up', element: <SignUp /> },
      { path: 'sign-up-bundles', element: <SignUp bundlesVariant /> },
    ],
  },

  /**
   * Accepting an account invitation
   */
  { path: 'account/team-members/accept/:uuid', element: <AcceptInvite /> },

  // Market Intelligence Report
  { path: 'market-intelligence', element: <MarketIntelligence /> },

  /**
   * Authorized routes
   */
  {
    element: (
      <PageWithFooter>
        <AuthorizedPage>
          <Outlet />
        </AuthorizedPage>
      </PageWithFooter>
    ),
    children: [
      { path: 'onboarding/*', element: <OnboardingRoutes /> },

      /**
       * Routes with only general nav bar
       */
      {
        element: (
          <PageWithAuthenticatedNavs>
            <Outlet />
          </PageWithAuthenticatedNavs>
        ),
        children: [
          {
            path: 'account',
            children: [
              {
                path: 'billing',
                children: [
                  { index: true, element: <MembershipManagement /> },
                  { path: 'edit', element: <MembershipEditor /> },
                  { path: 'content-pro', element: <ContentProMigration /> },
                ],
              },
              { path: 'carrot-crm', element: <CarrotCrmOptIn /> },
              { path: 'team-members/*', element: <TeamMembersRoutes /> },
              {
                path: 'switch/:accountId',
                element: <SwitchAccount />,
              },
            ],
          },
          { path: 'keyword-explorer', element: <KeywordExplorerOrUpsell /> },
        ],
      },

      /**
       * Single-site routes
       */
      {
        path: 'account/site/:siteId',
        element: (
          <PageWithAuthenticatedNavs showSingleSiteNav>
            <Outlet />
          </PageWithAuthenticatedNavs>
        ),
        errorElement: <ErrorPage singleSiteRoute />,
        children: [
          // There is currently no root single-site page, so we render the 404
          // page. This path may be used in the future.
          { index: true, element: <PageNotFound contained={false} /> },
          { path: 'analytics', element: <Analytics /> },
          { path: 'content-overview', element: <ContentOverview /> },
          {
            path: 'seo',
            children: [
              { index: true, element: <Navigate to="domain-overview" /> },
              { path: 'domain-overview', element: <DomainOverview /> },
              { path: 'backlink-analysis', element: <BacklinkAnalysis /> },
              { path: 'search-performance', element: <SearchPerformance /> },
              { path: 'site-audit/*', element: <SiteAuditRoutes /> },
            ],
          },

          // Used for tests:
          errorRoute,
          {
            path: 'test-route',
            element: <div>Authorized single-site route</div>,
          },

          // Using this rather than letting react-router throw a 404 when a
          // single-site route that doesn't exist is requested allows the error
          // element on the single-site routes to catch the error rather than
          // the top-level error element. This means the single-site nav will
          // be rendered, which is a better experience for the user.
          {
            path: '*',
            element: null,
            loader: () => {
              throw new Response('Not found', { status: 404 });
            },
          },
        ],
      },

      /**
       * Superadmin routes
       */
      {
        path: 'super',
        element: (
          <PageWithAuthenticatedNavs>
            <SuperAuthorizedPage>
              <Outlet />
            </SuperAuthorizedPage>
          </PageWithAuthenticatedNavs>
        ),
        children: [
          {
            path: 'bundles',
            children: [
              { index: true, element: <SuperBundlesList /> },
              { path: 'create', element: <SuperBundlesCreate /> },
            ],
          },
          {
            path: 'test-route',
            element: <div>Authorized superadmin route</div>,
          },
        ],
      },

      // Used for tests:
      errorRoute,
      {
        path: 'test-route',
        element: <div>Authorized route</div>,
      },
    ],
  },
];
