import type { QueryClientConfig } from '@tanstack/react-query';

const THIRTY_MINUTES_IN_MS = 1000 * 60 * 30;
export const queryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: THIRTY_MINUTES_IN_MS,
    },
  },
} satisfies QueryClientConfig;
