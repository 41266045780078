import { useQuery } from '@tanstack/react-query';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { authenticationEndpoint } from 'utils/carrotUrls';

function useJwt() {
  return useQuery({
    queryKey: ['jwt'],
    queryFn: async (): Promise<{ token: string }> => {
      const response = await fetch(authenticationEndpoint, {
        credentials: 'include',
      });
      return response.json();
    },
    staleTime: Infinity,
  });
}

/** Data in the JWT from WordPress */
interface CarrotTokenUserData {
  /** The WordPress user ID */
  id: string;
  avatarUrl: string | null;
}

interface CarrotTokenPayload extends JwtPayload {
  data: {
    user: CarrotTokenUserData;
  };
}

function userDataFromToken(token: string | undefined) {
  if (!token) {
    return null;
  }

  const { id, avatarUrl } = jwtDecode<CarrotTokenPayload>(token).data.user;

  return { id, avatarUrl };
}

export { useJwt, userDataFromToken };
export type { CarrotTokenPayload, CarrotTokenUserData };
