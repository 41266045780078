import * as Sentry from '#utils/sentry';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useEffect } from 'react';

export function useSentryUser() {
  const { data } = useCurrentUser();

  useEffect(() => {
    if (data?.user) {
      Sentry.setUser({
        id: data.user.wordpressUserId.toString(),
        username: data.user.wordpressUsername,
        email: data.user.email,
      });
    }
  }, [data?.user]);
}
