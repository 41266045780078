import { graphql } from 'generated/gql/account-manager';
import { useAmQuery } from 'graphql/useAmQuery';
import { useParams, useSearchParams } from 'react-router-dom';
import { useJwt, userDataFromToken } from './useJwt';

const teamMemberAbilitiesQueryDocument = graphql(`
  query teamMemberAbilities(
    $wordpressUserId: Int
    $accountId: Int
    $wordpressBlogId: Int
  ) {
    teamMemberAbility(
      wordpressUserId: $wordpressUserId
      accountId: $accountId
      wordpressBlogId: $wordpressBlogId
    ) {
      action
      subject
      limitingIds
    }
  }
`);

export function useTeamMemberAbilities() {
  const { accountId, siteId } = useParams();
  const hasAccountOrSiteId = !!accountId || !!siteId;
  const [searchParams] = useSearchParams();

  const { data } = useJwt();
  const wordpressUserId = userDataFromToken(data?.token)?.id;

  return useAmQuery(
    teamMemberAbilitiesQueryDocument,
    {
      wordpressUserId: Number(wordpressUserId),
      accountId: Number(accountId),
      wordpressBlogId: Number(siteId),
    },
    {
      enabled:
        !!wordpressUserId && hasAccountOrSiteId && searchParams.has('newPerms'),
    }
  );
}
