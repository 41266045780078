import { faRepeat } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from '@headlessui/react';
import clsx from 'clsx';
import type { CurrentUser } from 'hooks/useCurrentUser';
import { useId, useState } from 'react';
import { Link } from 'turnip/Link/Link';
import { routes } from 'utils/carrotUrls';
import { itemLinkClasses, submenuButtonClasses } from '../Submenu/classes';

function AccountSwitcher({ accounts }: { accounts: CurrentUser['accounts'] }) {
  const [filter, setFilter] = useState('');
  const accountsListId = useId();

  const maxVisibleAccounts = 10;
  const filteredAndLimitedAccounts = accounts
    .filter((account) =>
      account.name?.toLowerCase()?.includes(filter.toLowerCase())
    )
    .slice(0, maxVisibleAccounts);

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            className={clsx(
              submenuButtonClasses(open),
              'border-l-2 border-solid border-[#ee8408] px-4'
            )}
          >
            <span className="sr-only">Open account switcher</span>
            <FontAwesomeIcon icon={faRepeat} />
          </Popover.Button>

          <Popover.Panel
            className="absolute z-30 min-w-64 bg-white shadow-md"
            aria-label="Account switcher"
          >
            {accounts.length >= maxVisibleAccounts && (
              <>
                <p className="px-2.5 pt-1.5 text-sm text-gray-700">
                  Showing {filteredAndLimitedAccounts.length} of{' '}
                  {accounts.length} accounts
                </p>

                <label className="block px-2.5 py-1.5">
                  <input
                    type="search"
                    placeholder="Search Accounts"
                    name="filter"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    className="w-full border border-[#ccc] text-[#555] focus:border-orange-500 focus:ring-0"
                    aria-controls={accountsListId}
                  />
                  <span className="sr-only">Filter</span>
                </label>
              </>
            )}

            <ul id={accountsListId} aria-label="Accounts" aria-live="polite">
              {filteredAndLimitedAccounts.map(({ id, name }) => (
                <li key={id}>
                  <Link
                    to={routes(id).switchAccount}
                    className={clsx(
                      itemLinkClasses(false),
                      'focus:text-orange-700'
                    )}
                  >
                    {name}
                  </Link>
                </li>
              ))}
            </ul>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
}

export { AccountSwitcher };
