import type { OverridedMixpanel } from '#mixpanel';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { GraphQlProvider } from 'context/GraphQlProvider/GraphQlProvider';
import { MixpanelProvider } from 'context/MixpanelProvider/MixpanelProvider';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useJwt } from 'hooks/useJwt';
import { useTeamMemberAbilities } from 'hooks/useTeamMemberAbilities.am';
import { useEffect } from 'react';
import { ScrollRestoration } from 'react-router-dom';
import { CenteredPageLoadingCarrot } from 'turnip/LoadingCarrot/LoadingCarrot';
import { ThirdPartyScriptLoader } from './ThirdPartyScriptLoader/ThirdPartyScriptLoader';
import { useIntercomUser } from './useIntercomUser';
import { useSentryUser } from './useSentryUser';

export type RootProps = {
  queryClient: QueryClient;
  mixpanel?: OverridedMixpanel;
  googleAnalyticsId: string;
  intercomId: string;
};

export function Root({
  queryClient,
  mixpanel,
  googleAnalyticsId,
  intercomId,
  children,
}: RootProps & { children: React.ReactNode }) {
  // Initialize carrot global namespace; we occasionally stick something useful
  // for tests in here
  useEffect(() => {
    window.carrot = window.carrot ?? {};
  }, []);

  const isTestRunner = window.navigator.userAgent.match(/StorybookTestRunner/);

  return (
    <QueryClientProvider client={queryClient}>
      <MixpanelProvider mixpanel={mixpanel}>
        <UserLoader>
          <GraphQlProvider>
            <ThirdPartyScriptLoader
              googleAnalyticsId={googleAnalyticsId}
              intercomId={intercomId}
            />

            {children}
          </GraphQlProvider>
        </UserLoader>
      </MixpanelProvider>

      <ScrollRestoration />
      {!isTestRunner && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
}

function UserLoader({ children }: { children: React.ReactNode }) {
  const jwtQuery = useJwt();
  const userQuery = useCurrentUser();
  const abilitiesQuery = useTeamMemberAbilities();

  useIntercomUser();
  useSentryUser();

  if (jwtQuery.isPending || userQuery.isLoading || abilitiesQuery.isLoading) {
    return <CenteredPageLoadingCarrot title="Loading user data" />;
  }

  return children;
}
