import { useAmQuery } from 'graphql/useAmQuery';
import {
  type Interval,
  isCustomerType,
  isInterval,
  type CustomerType,
} from 'types/Users';
import { CarrotTokenUserData, useJwt, userDataFromToken } from './useJwt';
import type { UseQueryResult } from '@tanstack/react-query';
import { currentUserQueryDocument } from './useCurrentUser.graphql.am';
import { CurrentUserQuery } from 'generated/gql/account-manager/graphql';
import { Maybe } from 'types/Utilities';

/** We modify the query results for convenience */
type CurrentUser = Omit<
  NonNullable<CurrentUserQuery['user']>,
  'id' | 'createdAt'
> & {
  accountManagerUserId: number;
  avatarUrl: CarrotTokenUserData['avatarUrl'] | undefined;
  createdAt: Date;
  /** This is copied from currentSubscription as a convenience */
  customerType: CustomerType | null;
  /** This is copied from currentSubscription as a convenience */
  interval: Interval | null;
  /** Pricing and packaging version. This is copied from currentSubscription as a convenience. */
  packagingVersion: Maybe<number>;
};

type UseCurrentUserQueryResult = UseQueryResult<CurrentUserData>;
type CurrentUserData = { user?: CurrentUser };

function useCurrentUser(): UseCurrentUserQueryResult {
  const { data } = useJwt();
  const userData = userDataFromToken(data?.token);
  const wordpressUserId = Number(userData?.id);

  return useAmQuery(
    currentUserQueryDocument,
    wordpressUserId ? { wordpressUserId } : undefined,
    {
      enabled: !!wordpressUserId,
      select: (data) => transformCurrentUser(data, userData?.avatarUrl),
    }
  );
}

/** Make the returned user query data more convenient to use */
function transformCurrentUser(
  data: CurrentUserQuery,
  avatarUrl: Maybe<string>
): CurrentUserData {
  if (!data.user) {
    return { user: undefined };
  }

  const starter = data.user?.currentSubscription?.items.find(
    (item) => item.price.product.starter
  );
  const customerType = starter?.price.customerType;
  const interval = starter?.price.interval;

  return {
    user: {
      ...data.user,
      accountManagerUserId: data.user.id,
      avatarUrl,
      createdAt: new Date(data.user.createdAt),
      customerType: isCustomerType(customerType) ? customerType : null,
      interval: isInterval(interval) ? interval : null,
      packagingVersion: starter?.price.version,
    },
  };
}

export type { UseCurrentUserQueryResult, CurrentUser };
export { useCurrentUser };
