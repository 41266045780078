import { type TypedDocumentNode } from '@graphql-typed-document-node/core';
import {
  useQuery,
  UseQueryResult,
  type UndefinedInitialDataOptions,
} from '@tanstack/react-query';
import { request } from 'graphql-request';
import { accountManagerGraphQlUrl, useAuthorizationHeader } from './helpers';

export function useAmQuery<TResult, TVariables, TSelectedData = TResult>(
  document: TypedDocumentNode<TResult, TVariables>,
  variables?: TVariables,
  options?: Omit<
    UndefinedInitialDataOptions<TResult>,
    'queryKey' | 'queryFn' | 'select'
  > & { select?: (data: TResult) => TSelectedData }
): UseQueryResult<TSelectedData> {
  const requestHeaders = useAuthorizationHeader();

  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (document.definitions[0] as any).name.value,
      variables,
      requestHeaders,
    ],
    queryFn: async () =>
      request({
        url: accountManagerGraphQlUrl,
        document,
        variables: variables ?? undefined,
        requestHeaders,
      }),
    ...options,
  });
}
