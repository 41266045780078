import { type TypedDocumentNode } from '@graphql-typed-document-node/core';
import { useMutation, type UseMutationOptions } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';
import { accountManagerGraphQlUrl, useAuthorizationHeader } from './helpers';

export function useAmMutation<TResult, TVariables>(
  document: TypedDocumentNode<TResult, TVariables>,
  options?: UseMutationOptions<TResult, Error, TVariables, unknown>,
  requestConfiguration: GraphQLClient['requestConfig'] = {}
) {
  const requestHeaders = useAuthorizationHeader();
  const graphQlClient = new GraphQLClient(
    accountManagerGraphQlUrl,
    requestConfiguration
  );

  return useMutation({
    mutationFn: async (variables: TVariables) =>
      graphQlClient.request({
        document,
        variables: variables ?? undefined,
        requestHeaders,
      }),
    ...options,
  });
}
