import cx from 'clsx';
import {
  responsiveClasses,
  type ResponsiveProp,
} from 'turnip/responsiveClasses';

type TextSize =
  | 'xs'
  | 'sm'
  | 'base'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl'
  | '6xl';

type TextSizeClass =
  | `text-${TextSize}`
  | `${string}:text-${TextSize}`
  | `text-${TextSize}/${string}`
  | `${string}:text-${TextSize}/${string}`;

/**
 * Text alignments available for headings
 *
 * NOTE: the classes (including responsive variants) must be safelisted in TW
 */
type TextAlign = 'left' | 'center';

interface HeadingProps {
  /** Children */
  children: React.ReactNode;
  /** Heading level */
  level: 1 | 2 | 3 | 4 | 5 | 6;
  /** Text size */
  size: TextSizeClass | TextSizeClass[];
  /** Text align */
  align?: ResponsiveProp<TextAlign>;
  id?: string;
}

export function Heading({ align, children, level, size, id }: HeadingProps) {
  const classes = cx(
    'font-bold text-gray-900',
    size,
    responsiveClasses(align, (align) => `text-${align}`)
  );

  const Component = `h${level}` as React.ElementType;

  return (
    <Component className={classes} id={id}>
      {children}
    </Component>
  );
}
