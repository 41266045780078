import { useCurrentUser } from 'hooks/useCurrentUser';
import { BrowserUpdate } from './BrowserUpdate';
import { useGoogleAnalyticsScript } from './useGoogleAnalyticsScript';
import { useIntercomScript } from './useIntercomScript';
import { usePageViewTracking } from './usePageViewTracking';
import { useSegmetricsScript } from './useSegmetricsScript';

type Gtag = (...args: unknown[]) => void;

interface Intercom {
  (...args: unknown[]): unknown;
  c: (args: unknown[]) => unknown;
  q: unknown[];
}

declare global {
  interface Window {
    dataLayer?: Array<IArguments | object>;
    gtag?: Gtag;
    Intercom?: Intercom;
    intercomSettings?: { hide_default_launcher: boolean };
  }
}

interface ScriptLoaderProps {
  googleAnalyticsId: string;
  intercomId: string;
}
export function ThirdPartyScriptLoader(props: ScriptLoaderProps) {
  // INFO: We don't load tracking scripts for super admins
  const { data } = useCurrentUser();
  const isSuperAdmin = data?.user?.superAdmin;

  return isSuperAdmin ? null : <ScriptLoader {...props} />;
}

function ScriptLoader({ googleAnalyticsId, intercomId }: ScriptLoaderProps) {
  useGoogleAnalyticsScript(googleAnalyticsId);
  usePageViewTracking();
  useIntercomScript(intercomId);
  useSegmetricsScript();

  return <BrowserUpdate />;
}
