import { useCurrentUser } from 'hooks/useCurrentUser';
import { useEffect } from 'react';
import { customerTypeMatches } from 'utils/users';

export function useIntercomUser() {
  const { data } = useCurrentUser();

  useEffect(() => {
    if (data?.user && window.Intercom) {
      const isHybrid = data.user.customerType === 'hybrid';
      window.Intercom('update', {
        user_id: data.user.wordpressUsername,
        Investor:
          customerTypeMatches(data.user.customerType, 'investor') || isHybrid,
        Agent: customerTypeMatches(data.user.customerType, 'agent') || isHybrid,
        email: data.user.email,
        name: data.user.name,
        created_at: data.user.createdAt.valueOf() / 1000,
        user_hash: data.user.intercomUserHash,
        last_request_at: Math.floor(Date.now() / 1000),
        Membership:
          data.user.currentSubscription?.items[0]?.price.wordpressLabel,
      });
    }
  }, [data?.user]);
}
