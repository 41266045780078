import { graphql } from 'generated/gql/account-manager';
import { useAmMutation } from 'graphql/useAmMutation';
import { useEffect } from 'react';
import { useCurrentUrl } from 'utils/useCurrentUrl';

const stripeCustomerPortalMutationDocument = graphql(`
  mutation stripeCustomerPortal($input: StripeCustomerPortalInput!) {
    stripeCustomerPortal(input: $input) {
      url
      errors {
        message
      }
    }
  }
`);

export function useCustomerPortalUrl(wordpressUserId: number) {
  const currentUrl = useCurrentUrl();
  const { mutate, isIdle, isPending, data } = useAmMutation(
    stripeCustomerPortalMutationDocument
  );

  useEffect(() => {
    mutate({ input: { wordpressUserId, returnUrl: currentUrl } });
  }, [currentUrl, mutate, wordpressUserId]);

  return {
    customerPortalUrl: data?.stripeCustomerPortal?.url,
    loading: isIdle || isPending,
  };
}
